.comics{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
}

.comics-title{
    width: 100%;
    font-size: 1.2rem;
}

.comics-card{
    width: 20%;
    margin: 0px 3px;
    padding: 15px;
    background-color: $color-secondary;
    color: black;
    margin-bottom: 15px;
    border-radius: 3px;
    text-align: center;
}

