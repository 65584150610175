$color-primary: #2882b6;
$color-secondary: rgb(238, 236, 224);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, "Grandstander", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: black;
    color: #fff;
}

.container {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

a{
    color: inherit;
}

input{
    border-radius: 2px;
    padding: 5px;
    border: 2px solid #ccc;
    background-color: transparent;
    transition: all 0.2s ease;

    &:focus{
        outline: none;
        border-color: $color-primary;
        box-shadow: 0px 0px 1px 2px $color-primary;
    }
}

button{
    padding: 5px;
    background-color: $color-primary;
    color: #fff;
    border: none;
    border-radius: 2px;
    text-shadow: 0px 1px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover{
        background-color: darken($color: $color-primary, $amount: 15%);
    }
}

.mb-1 {
    margin-bottom: 5px;
}
.mb-2 {
    margin-bottom: 10px;
}
.mb-3 {
    margin-bottom: 20px;
}

.mt-1 {
    margin-top: 5px;
}
.mt-2 {
    margin-top: 10px;
}
.mt-3 {
    margin-top: 20px;
}

.mr-1 {
    margin-right: 5px;
}
.mr-2 {
    margin-right: 10px;
}
.mr-3 {
    margin-right: 20px;
}
