.form-search{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgb(238, 236, 224);
    padding: 20px;
    margin: 0 auto;
    border-radius: 3px;
}

.form-input{
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0 auto;

    input{
        flex: 3 1 auto;
    }

    button{
        flex: 1 1 auto;
    };
}